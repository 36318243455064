import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import keycloak from './keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import MyRoutes from './routes/MyRoutes';

const keycloakProviderInitConfig = {
  onLoad: 'login-required',
  checkLoginIframe: false,
};

export default function AppWrapper() {
  return (
    <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakProviderInitConfig}>
      <BrowserRouter>
        <MyRoutes />
      </BrowserRouter>
    </ReactKeycloakProvider>
  );
}
