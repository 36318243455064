import React, { useEffect } from 'react';
import logo from './../logo.svg';

import { TerminalContextProvider, ReactTerminal } from 'react-terminal';
import { useKeycloak } from '@react-keycloak/web';

const commandCategories = {
  General: [
    'help',
    'change_prompt',
    'change_theme',
    'toggle_control_bar',
    'toggle_control_buttons',
    'evaluate_math_expression',
    'account',
    'logout',
  ],
  Auth: ['AuthToken', 'AuthUserinfo', 'AuthTokenRefresh', 'AuthUsersCreate'],
  DataVisualization: ['DvWorkflow', 'DvWorkflowFile'],
  DSL: [
    'DslWorkflows',
    'DslWorkflow',
    'DslWorkflowDelete',
    'DslWorkflowStart',
    'DslWorkflowStop',
    'DslInsituDataUpload',
    'DslMarketplace',
  ],
  EO: [
    'EoConformance',
    'EoWellKnownOpenEO',
    'EoCredentialsBasic',
    'EoCredentialsOidc',
    'EoMe',
    'EoProcesses',
    'EoProcessGraphs',
    'EoProcessGraph',
    'EoProcessGraphUpdate',
    'EoProcessGraphDelete',
    'EoFileFormats',
    'EoResult',
    'EoJobs',
    'EoJobsCreate',
    'EoJobUpdate',
    'EoJob',
    'EoJobDelete',
    'EoJobEstimate',
    'EoJobLogs',
    'EoJobResults',
    'EoJobResultsCreate',
    'EoJobResultsDelete',
    'EoCollections',
    'EoCollection',
    'EoCollectionQueryables',
    'EoServiceTypes',
    'EoServices',
    'EoServicesCreate',
    'EoServiceUpdate',
    'EoService',
    'EoServiceDelete',
    'EoServiceLogs',
    'EoFiles',
    'EoFileUpload',
    'EoFile',
    'EoFileDelete',
  ],
  KG: [
    'KgStartService',
    'KgServiceStatus',
    'KgExecuteQuery',
    'KgExecuteAdvancedQuery',
    'KgSearchBasicMetaDataSet',
    'KgSearchDataSet',
    'KgSearchDataSetV1',
    'KgSearchDataSetV2',
    'KgSearchRelatedDataSets',
    'KgSearchFullMetadata',
    'KgSearchDatasetBreakdown',
    'KgSearchDatasetBreakdownPost',
    'KgSearchDatasetBreakdownWithOption',
    'KgGenerateApiCall',
    'KgGenerateApiCallPost',
  ],
  S3: [
    'S3BucketCreate',
    'S3Buckets',
    'S3Bucket',
    'S3BucketDelete',
    'S3BucketFilesUpload',
    'S3BucketFiles',
    'S3BucketFilesPreview',
    'S3BucketFilesDelete',
    'S3FilesUpload',
    'S3FilesUploadConf',
    'S3FilesJsDef',
  ],
  WorkflowProducts: [
    'WorkflowProducts',
    'WorkflowProductsCreate',
    'WorkflowProduct',
    'WorkflowProductUpdate',
    'WorkflowProductDelete',
  ],
  Users: [
    'UsersCount',
    'Users',
    'UsersCreate',
    'User',
    'UserUpdate',
    'UserDelete',
    'UserHistory',
    'UserCredentials',
    'UserResetPassword',
  ],
};

// Command help information with descriptions and usage
const commandHelp = {
  // General Commands
  help: {
    description: 'Displays help. Use without arguments for all commands or with a command name for details.',
    usage: 'help [command]',
  },
  change_prompt: { description: 'Changes the terminal prompt.', usage: 'change_prompt <new_prompt>' },
  change_theme: {
    description:
      'Changes the terminal theme. Valid themes: light, dark, material-light, material-dark, material-ocean, matrix, dracula.',
    usage: 'change_theme <theme>',
  },
  toggle_control_bar: { description: 'Toggles the control bar visibility.', usage: 'toggle_control_bar' },
  toggle_control_buttons: { description: 'Toggles the control buttons visibility.', usage: 'toggle_control_buttons' },
  evaluate_math_expression: {
    description: 'Evaluates a math expression using MathJS API.',
    usage: 'evaluate_math_expression <expression>',
  },
  account: { description: 'Opens the account management page.', usage: 'account' },
  logout: { description: 'Logs out the current user.', usage: 'logout' },
  // Auth Commands
  AuthToken: { description: 'Obtains an authentication token.', usage: 'AuthToken <username> <password> <otp>' },
  AuthUserinfo: { description: 'Retrieves user information.', usage: 'AuthUserinfo' },
  AuthTokenRefresh: { description: 'Refreshes the authentication token.', usage: 'AuthTokenRefresh <refresh_token>' },
  AuthUsersCreate: {
    description: 'Creates a new user. Expects JSON data.',
    usage: 'AuthUsersCreate <user_data_json> e.g., {"username": "test", "password": "test"}',
  },
  // DataVisualization Commands
  DvWorkflow: {
    description: 'Retrieves workflow data by ID with optional prefix.',
    usage: 'DvWorkflow <workflow_id> [prefix]',
  },
  DvWorkflowFile: {
    description: 'Retrieves a file from a workflow.',
    usage: 'DvWorkflowFile <workflow_id> <file_name>',
  },
  // DSL Commands
  DslWorkflows: {
    description: 'Lists workflows with status and CFS inclusion.',
    usage: 'DslWorkflows <status> <withCfs>',
  },
  DslWorkflow: { description: 'Retrieves details of a workflow.', usage: 'DslWorkflow <workflow_id>' },
  DslWorkflowDelete: { description: 'Deletes a workflow.', usage: 'DslWorkflowDelete <workflow_id>' },
  DslWorkflowStart: { description: 'Starts a workflow.', usage: 'DslWorkflowStart <workflow_id>' },
  DslWorkflowStop: { description: 'Stops a workflow.', usage: 'DslWorkflowStop <workflow_id>' },
  DslInsituDataUpload: {
    description: 'Uploads in-situ data (not supported in terminal).',
    usage: 'DslInsituDataUpload <workflow_id> <file> <metaData>',
  },
  DslMarketplace: { description: 'Retrieves marketplace data.', usage: 'DslMarketplace' },
  // EO Commands
  EoConformance: { description: 'Retrieves OpenEO conformance info.', usage: 'EoConformance' },
  EoWellKnownOpenEO: { description: 'Retrieves well-known OpenEO info.', usage: 'EoWellKnownOpenEO' },
  EoCredentialsBasic: { description: 'Retrieves basic credentials.', usage: 'EoCredentialsBasic' },
  EoCredentialsOidc: { description: 'Retrieves OIDC credentials.', usage: 'EoCredentialsOidc' },
  EoMe: { description: 'Retrieves current user info.', usage: 'EoMe' },
  EoProcesses: { description: 'Lists processes with optional limit.', usage: 'EoProcesses [limit]' },
  EoProcessGraphs: { description: 'Lists process graphs with optional limit.', usage: 'EoProcessGraphs [limit]' },
  EoProcessGraph: { description: 'Retrieves a process graph.', usage: 'EoProcessGraph <process_graph_id>' },
  EoProcessGraphUpdate: {
    description: 'Updates a process graph. Expects JSON data.',
    usage: 'EoProcessGraphUpdate <process_graph_id> <process_graph_data_json>',
  },
  EoProcessGraphDelete: { description: 'Deletes a process graph.', usage: 'EoProcessGraphDelete <process_graph_id>' },
  EoFileFormats: { description: 'Retrieves supported file formats.', usage: 'EoFileFormats' },
  EoResult: { description: 'Submits a result request. Expects JSON data.', usage: 'EoResult <result_data_json>' },
  EoJobs: { description: 'Lists jobs with optional limit.', usage: 'EoJobs [limit]' },
  EoJobsCreate: { description: 'Creates a job. Expects JSON data.', usage: 'EoJobsCreate <job_data_json>' },
  EoJobUpdate: { description: 'Updates a job. Expects JSON data.', usage: 'EoJobUpdate <job_id> <job_data_json>' },
  EoJob: { description: 'Retrieves a job.', usage: 'EoJob <job_id>' },
  EoJobDelete: { description: 'Deletes a job.', usage: 'EoJobDelete <job_id>' },
  EoJobEstimate: { description: 'Estimates a job cost.', usage: 'EoJobEstimate <job_id>' },
  EoJobLogs: { description: 'Retrieves job logs.', usage: 'EoJobLogs <job_id>' },
  EoJobResults: { description: 'Retrieves job results.', usage: 'EoJobResults <job_id>' },
  EoJobResultsCreate: {
    description: 'Creates job results. Expects JSON data.',
    usage: 'EoJobResultsCreate <job_id> <result_data_json>',
  },
  EoJobResultsDelete: { description: 'Deletes job results.', usage: 'EoJobResultsDelete <job_id>' },
  EoCollections: { description: 'Lists collections with optional limit.', usage: 'EoCollections [limit]' },
  EoCollection: { description: 'Retrieves a collection.', usage: 'EoCollection <collection_id>' },
  EoCollectionQueryables: {
    description: 'Retrieves queryables for a collection.',
    usage: 'EoCollectionQueryables <collection_id>',
  },
  EoServiceTypes: { description: 'Retrieves service types.', usage: 'EoServiceTypes' },
  EoServices: { description: 'Lists services.', usage: 'EoServices' },
  EoServicesCreate: {
    description: 'Creates a service. Expects JSON data.',
    usage: 'EoServicesCreate <service_data_json>',
  },
  EoServiceUpdate: {
    description: 'Updates a service. Expects JSON data.',
    usage: 'EoServiceUpdate <service_id> <service_data_json>',
  },
  EoService: { description: 'Retrieves a service.', usage: 'EoService <service_id>' },
  EoServiceDelete: { description: 'Deletes a service.', usage: 'EoServiceDelete <service_id>' },
  EoServiceLogs: { description: 'Retrieves service logs.', usage: 'EoServiceLogs <service_id>' },
  EoFiles: { description: 'Lists files with optional limit.', usage: 'EoFiles [limit]' },
  EoFileUpload: { description: 'Uploads a file (not supported in terminal).', usage: 'EoFileUpload <path> <file>' },
  EoFile: { description: 'Retrieves a file.', usage: 'EoFile <path>' },
  EoFileDelete: { description: 'Deletes a file.', usage: 'EoFileDelete <path>' },
  // KG Commands
  KgStartService: { description: 'Starts the KG service.', usage: 'KgStartService' },
  KgServiceStatus: { description: 'Retrieves KG service status.', usage: 'KgServiceStatus' },
  KgExecuteQuery: { description: 'Executes a KG query.', usage: 'KgExecuteQuery <query>' },
  KgExecuteAdvancedQuery: {
    description: 'Executes an advanced KG query.',
    usage: 'KgExecuteAdvancedQuery <query> <sources>',
  },
  KgSearchBasicMetaDataSet: { description: 'Searches basic metadata.', usage: 'KgSearchBasicMetaDataSet <values>' },
  KgSearchDataSet: { description: 'Searches datasets.', usage: 'KgSearchDataSet <values>' },
  KgSearchDataSetV1: { description: 'Searches datasets (v1).', usage: 'KgSearchDataSetV1 <values> <deprecatedFormat>' },
  KgSearchDataSetV2: { description: 'Searches datasets (v2).', usage: 'KgSearchDataSetV2 <values>' },
  KgSearchRelatedDataSets: { description: 'Searches related datasets.', usage: 'KgSearchRelatedDataSets <id>' },
  KgSearchFullMetadata: { description: 'Retrieves full metadata.', usage: 'KgSearchFullMetadata <id>' },
  KgSearchDatasetBreakdown: { description: 'Retrieves dataset breakdown.', usage: 'KgSearchDatasetBreakdown <id>' },
  KgSearchDatasetBreakdownPost: {
    description: 'Posts dataset breakdown. Expects JSON data.',
    usage: 'KgSearchDatasetBreakdownPost <id> <data_json>',
  },
  KgSearchDatasetBreakdownWithOption: {
    description: 'Retrieves dataset breakdown with options.',
    usage: 'KgSearchDatasetBreakdownWithOption <id> <options>',
  },
  KgGenerateApiCall: {
    description: 'Generates an API call.',
    usage: 'KgGenerateApiCall <id> <optionsJson> <downloadOption>',
  },
  KgGenerateApiCallPost: {
    description: 'Generates an API call with data. Expects JSON data.',
    usage: 'KgGenerateApiCallPost <id> <data_json>',
  },
  // S3 Commands
  S3BucketCreate: { description: 'Creates an S3 bucket.', usage: 'S3BucketCreate <bucket_name>' },
  S3Buckets: { description: 'Lists all S3 buckets.', usage: 'S3Buckets' },
  S3Bucket: { description: 'Retrieves an S3 bucket.', usage: 'S3Bucket <bucket_name>' },
  S3BucketDelete: { description: 'Deletes an S3 bucket.', usage: 'S3BucketDelete <bucket_name>' },
  S3BucketFilesUpload: {
    description: 'Uploads files to an S3 bucket (not supported in terminal).',
    usage: 'S3BucketFilesUpload <bucket_name> <file> [prefix]',
  },
  S3BucketFiles: { description: 'Lists files in an S3 bucket.', usage: 'S3BucketFiles <bucket_name> [prefix]' },
  S3BucketFilesPreview: {
    description: 'Previews files in an S3 bucket. Expects JSON data.',
    usage: 'S3BucketFilesPreview <bucket_name> <file_path_data_json>',
  },
  S3BucketFilesDelete: {
    description: 'Deletes a file from an S3 bucket.',
    usage: 'S3BucketFilesDelete <bucket_name> <key>',
  },
  S3FilesUpload: { description: 'Uploads a file to S3 (not supported in terminal).', usage: 'S3FilesUpload <file>' },
  S3FilesUploadConf: {
    description: 'Uploads a config file to S3 (not supported in terminal).',
    usage: 'S3FilesUploadConf <config_file>',
  },
  S3FilesJsDef: { description: 'Defines JS file config. Expects JSON data.', usage: 'S3FilesJsDef <file_config_json>' },
  // WorkflowProducts Commands
  WorkflowProducts: { description: 'Lists all workflow products.', usage: 'WorkflowProducts' },
  WorkflowProductsCreate: {
    description: 'Creates a workflow product. Expects JSON data.',
    usage: 'WorkflowProductsCreate <product_data_json>',
  },
  WorkflowProduct: { description: 'Retrieves a workflow product.', usage: 'WorkflowProduct <id>' },
  WorkflowProductUpdate: {
    description: 'Updates a workflow product. Expects JSON data.',
    usage: 'WorkflowProductUpdate <id> <product_data_json>',
  },
  WorkflowProductDelete: { description: 'Deletes a workflow product.', usage: 'WorkflowProductDelete <id>' },
  // Users Commands
  UsersCount: { description: 'Retrieves the count of users.', usage: 'UsersCount' },
  Users: { description: 'Lists all users.', usage: 'Users' },
  UsersCreate: { description: 'Creates a user. Expects JSON data.', usage: 'UsersCreate <user_data_json>' },
  User: { description: 'Retrieves a user.', usage: 'User <id>' },
  UserUpdate: { description: 'Updates a user. Expects JSON data.', usage: 'UserUpdate <id> <user_data_json>' },
  UserDelete: { description: 'Deletes a user.', usage: 'UserDelete <id>' },
  UserHistory: { description: 'Retrieves user history with pagination.', usage: 'UserHistory <id> [page] [pageSize]' },
  UserCredentials: { description: 'Retrieves user credentials.', usage: 'UserCredentials <id>' },
  UserResetPassword: {
    description: "Resets a user's password. Expects JSON data.",
    usage: 'UserResetPassword <id> <password_data_json>',
  },
};

export default function CustomTerminal() {
  const [theme, setTheme] = React.useState('dark');
  const [controlBar, setControlBar] = React.useState(true);
  const [controlButtons, setControlButtons] = React.useState(true);
  const [prompt, setPrompt] = React.useState('$');

  const { keycloak } = useKeycloak();
  const token = keycloak.token;
  const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_ROOT_URI;

  const fetchEndpoint = async (endpoint, { method = 'GET', queryParams = '', headers = {}, data = null } = {}) => {
    // Build the complete headers object, merging default headers with any provided headers.
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
      ...headers,
    };

    // Configure the request options.
    const config = {
      method,
      headers: requestHeaders,
    };

    // Only add the body for methods that support a payload (e.g., POST, PUT, DELETE, etc.).
    if (data && method !== 'GET') {
      config.body = JSON.stringify(data);
    }

    // Construct the full URL including query parameters.
    const url = `${API_URL}/${endpoint}${queryParams}`;

    const response = await fetch(url, config);
    console.log(response);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  };
  const commands = {
    // General Commands
    help: (args) => {
      if (args.length === 0) {
        return (
          <div>
            Available commands:
            <br />
            {Object.entries(commandCategories).map(([category, cmds]) => (
              <>
                {category}:<br />
                {cmds.map((cmd) => (
                  <>
                    {cmd}
                    <br />
                    &nbsp;&nbsp;description: {commandHelp[cmd].description}
                    <br />
                    &nbsp;&nbsp;usage: {commandHelp[cmd].usage}
                    <br />
                    <br />
                  </>
                ))}
                <br />
              </>
            ))}
            Type &quot;help &lt;command&gt;&quot; for detailed information.
          </div>
        );
      } else {
        const cmd = args;
        if (commandHelp[cmd]) {
          return (
            <div>
              {cmd}:<br />
              &nbsp;&nbsp;description: {commandHelp[cmd].description}
              <br />
              &nbsp;&nbsp;usage: {commandHelp[cmd].usage}
            </div>
          );
        }
        return <div>Command &quot;{cmd}&quot; not found.</div>;
      }
    },
    change_prompt: (args) => {
      if (args.length !== 1) return 'Usage: change_prompt <prompt>';
      setPrompt(args[0]);
      return 'Prompt changed to ' + args[0];
    },
    change_theme: (args) => {
      if (args.length !== 1) return 'Usage: change_theme <theme>';
      const validThemes = ['light', 'dark', 'material-light', 'material-dark', 'material-ocean', 'matrix', 'dracula'];
      if (!validThemes.includes(args[0])) return `Invalid theme. Valid themes: ${validThemes.join(', ')}`;
      setTheme(args[0]);
      return 'Theme changed to ' + args[0];
    },
    toggle_control_bar: (args) => {
      if (args.length !== 0) return 'Usage: toggle_control_bar';
      setControlBar(!controlBar);
      return `Control bar ${controlBar ? 'hidden' : 'shown'}`;
    },
    toggle_control_buttons: (args) => {
      if (args.length !== 0) return 'Usage: toggle_control_buttons';
      setControlButtons(!controlButtons);
      return `Control buttons ${controlButtons ? 'hidden' : 'shown'}`;
    },
    evaluate_math_expression: async (args) => {
      if (args.length === 0) return 'Usage: evaluate_math_expression <expression>';
      const expr = args.join(' ');
      try {
        const response = await fetch(`https://api.mathjs.org/v4/?expr=${encodeURIComponent(expr)}`);
        return await response.text();
      } catch (e) {
        return 'Error evaluating expression: ' + e.message;
      }
    },
    account: (args) => {
      if (args.length !== 0) return 'Usage: account';
      window.open(keycloak.createAccountUrl());
      return 'Opening account management page...';
    },
    logout: (args) => {
      if (args.length !== 0) return 'Usage: logout';
      window.open(keycloak.createLogoutUrl());
      return 'Logging out...';
    },
    // Auth Commands
    AuthToken: async (args) => {
      if (args.length !== 3) return 'Usage: AuthToken <username> <password> <otp>';
      const [username, password, otp] = args;
      try {
        const result = await fetchEndpoint('Auth/Token', { method: 'POST', headers: { username, password, otp } });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    AuthUserinfo: async (args) => {
      if (args.length !== 0) return 'Usage: AuthUserinfo';
      try {
        const result = await fetchEndpoint('Auth/Userinfo', { method: 'GET' });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    AuthTokenRefresh: async (args) => {
      if (args.length !== 1) return 'Usage: AuthTokenRefresh <refresh_token>';
      try {
        const result = await fetchEndpoint('Auth/Token/Refresh', {
          method: 'POST',
          queryParams: `?refresh_token=${encodeURIComponent(args[0])}`,
        });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    AuthUsersCreate: async (args) => {
      if (args.length !== 1) return 'Usage: AuthUsersCreate <user_data_json>';
      try {
        const userData = JSON.parse(args[0]);
        const result = await fetchEndpoint('Auth/users/create', { method: 'POST', data: userData });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error parsing JSON or making request: ' + e.message;
      }
    },
    // DataVisualization Commands
    DvWorkflow: async (args) => {
      if (args.length < 1 || args.length > 2) return 'Usage: DvWorkflow <workflow_id> [prefix]';
      const [workflowID, prefix = ''] = args;
      const queryParams = prefix ? `?prefix=${encodeURIComponent(prefix)}` : '';
      try {
        const result = await fetchEndpoint(`DataVisualization/workflow/${workflowID}`, { method: 'GET', queryParams });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    DvWorkflowFile: async (args) => {
      if (args.length !== 2) return 'Usage: DvWorkflowFile <workflow_id> <file_name>';
      const [workflowID, fileName] = args;
      try {
        const result = await fetchEndpoint(`DataVisualization/workflow/${workflowID}/file/${fileName}`, {
          method: 'GET',
        });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    // DSL Commands
    DslWorkflows: async (args) => {
      if (args.length !== 2) return 'Usage: DslWorkflows <status> <withCfs>';
      const [status, withCfs] = args;
      const queryParams = `?status=${encodeURIComponent(status)}&withCfs=${encodeURIComponent(withCfs)}`;
      try {
        const result = await fetchEndpoint('DSL/dsl-api/workflows', { method: 'GET', queryParams });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    DslWorkflow: async (args) => {
      if (args.length !== 1) return 'Usage: DslWorkflow <workflow_id>';
      try {
        const result = await fetchEndpoint('DSL/dsl-api/workflows/' + args[0], { method: 'GET' });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    DslWorkflowDelete: async (args) => {
      if (args.length !== 1) return 'Usage: DslWorkflowDelete <workflow_id>';
      try {
        const result = await fetchEndpoint('DSL/dsl-api/workflows/' + args[0], { method: 'DELETE' });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    DslWorkflowStart: async (args) => {
      if (args.length !== 1) return 'Usage: DslWorkflowStart <workflow_id>';
      try {
        const result = await fetchEndpoint('DSL/dsl-api/workflows/' + args[0] + '/start', { method: 'POST' });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    DslWorkflowStop: async (args) => {
      if (args.length !== 1) return 'Usage: DslWorkflowStop <workflow_id>';
      try {
        const result = await fetchEndpoint('DSL/dsl-api/workflows/' + args[0] + '/stop', { method: 'GET' });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    DslInsituDataUpload: (args) => 'File upload not supported in terminal.',
    DslMarketplace: async (args) => {
      if (args.length !== 0) return 'Usage: DslMarketplace';
      try {
        const result = await fetchEndpoint('DSL/dsl-api/marketplace', { method: 'GET' });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    // EO Commands
    EoConformance: async (args) => {
      if (args.length !== 0) return 'Usage: EoConformance';
      try {
        const result = await fetchEndpoint('EO/conformance');
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoWellKnownOpenEO: async (args) => {
      if (args.length !== 0) return 'Usage: EoWellKnownOpenEO';
      try {
        const result = await fetchEndpoint('EO/.well-known/openeo');
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoCredentialsBasic: async (args) => {
      if (args.length !== 0) return 'Usage: EoCredentialsBasic';
      try {
        const result = await fetchEndpoint('EO/credentials/basic');
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoCredentialsOidc: async (args) => {
      if (args.length !== 0) return 'Usage: EoCredentialsOidc';
      try {
        const result = await fetchEndpoint('EO/credentials/oidc');
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoMe: async (args) => {
      if (args.length !== 0) return 'Usage: EoMe';
      try {
        const result = await fetchEndpoint('EO/me');
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoProcesses: async (args) => {
      if (args.length > 1) return 'Usage: EoProcesses [limit]';
      const limit = args[0] || '';
      const queryParams = limit ? `?limit=${encodeURIComponent(limit)}` : '';
      try {
        const result = await fetchEndpoint('EO/processes', { queryParams });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoProcessGraphs: async (args) => {
      if (args.length > 1) return 'Usage: EoProcessGraphs [limit]';
      const limit = args[0] || '';
      const queryParams = limit ? `?limit=${encodeURIComponent(limit)}` : '';
      try {
        const result = await fetchEndpoint('EO/process_graphs', { queryParams });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoProcessGraph: async (args) => {
      if (args.length !== 1) return 'Usage: EoProcessGraph <process_graph_id>';
      try {
        const result = await fetchEndpoint(`EO/process_graphs/${args[0]}`);
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoProcessGraphUpdate: async (args) => {
      if (args.length !== 2) return 'Usage: EoProcessGraphUpdate <process_graph_id> <process_graph_data_json>';
      try {
        const processGraphData = JSON.parse(args[1]);
        const result = await fetchEndpoint(`EO/process_graphs/${args[0]}`, { method: 'PUT', data: processGraphData });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error parsing JSON or making request: ' + e.message;
      }
    },
    EoProcessGraphDelete: async (args) => {
      if (args.length !== 1) return 'Usage: EoProcessGraphDelete <process_graph_id>';
      try {
        const result = await fetchEndpoint(`EO/process_graphs/${args[0]}`, { method: 'DELETE' });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoFileFormats: async (args) => {
      if (args.length !== 0) return 'Usage: EoFileFormats';
      try {
        const result = await fetchEndpoint('EO/file_formats');
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoResult: async (args) => {
      if (args.length !== 1) return 'Usage: EoResult <result_data_json>';
      try {
        const resultData = JSON.parse(args[0]);
        const result = await fetchEndpoint('EO/result', { method: 'POST', data: resultData });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error parsing JSON or making request: ' + e.message;
      }
    },
    EoJobs: async (args) => {
      if (args.length > 1) return 'Usage: EoJobs [limit]';
      const limit = args[0] || '';
      const queryParams = limit ? `?limit=${encodeURIComponent(limit)}` : '';
      try {
        const result = await fetchEndpoint('EO/jobs', { queryParams });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoJobsCreate: async (args) => {
      if (args.length !== 1) return 'Usage: EoJobsCreate <job_data_json>';
      try {
        const jobData = JSON.parse(args[0]);
        const result = await fetchEndpoint('EO/jobs', { method: 'POST', data: jobData });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error parsing JSON or making request: ' + e.message;
      }
    },
    EoJobUpdate: async (args) => {
      if (args.length !== 2) return 'Usage: EoJobUpdate <job_id> <job_data_json>';
      try {
        const jobData = JSON.parse(args[1]);
        const result = await fetchEndpoint(`EO/jobs/${args[0]}`, { method: 'PATCH', data: jobData });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error parsing JSON or making request: ' + e.message;
      }
    },
    EoJob: async (args) => {
      if (args.length !== 1) return 'Usage: EoJob <job_id>';
      try {
        const result = await fetchEndpoint(`EO/jobs/${args[0]}`);
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoJobDelete: async (args) => {
      if (args.length !== 1) return 'Usage: EoJobDelete <job_id>';
      try {
        const result = await fetchEndpoint(`EO/jobs/${args[0]}`, { method: 'DELETE' });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoJobEstimate: async (args) => {
      if (args.length !== 1) return 'Usage: EoJobEstimate <job_id>';
      try {
        const result = await fetchEndpoint(`EO/jobs/${args[0]}/estimate`);
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoJobLogs: async (args) => {
      if (args.length !== 1) return 'Usage: EoJobLogs <job_id>';
      try {
        const result = await fetchEndpoint(`EO/jobs/${args[0]}/logs`);
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoJobResults: async (args) => {
      if (args.length !== 1) return 'Usage: EoJobResults <job_id>';
      try {
        const result = await fetchEndpoint(`EO/jobs/${args[0]}/results`);
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoJobResultsCreate: async (args) => {
      if (args.length !== 2) return 'Usage: EoJobResultsCreate <job_id> <result_data_json>';
      try {
        const resultData = JSON.parse(args[1]);
        const result = await fetchEndpoint(`EO/jobs/${args[0]}/results`, { method: 'POST', data: resultData });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error parsing JSON or making request: ' + e.message;
      }
    },
    EoJobResultsDelete: async (args) => {
      if (args.length !== 1) return 'Usage: EoJobResultsDelete <job_id>';
      try {
        const result = await fetchEndpoint(`EO/jobs/${args[0]}/results`, { method: 'DELETE' });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoCollections: async (args) => {
      if (args.length > 1) return 'Usage: EoCollections [limit]';
      const limit = args[0] || '';
      const queryParams = limit ? `?limit=${encodeURIComponent(limit)}` : '';
      try {
        const result = await fetchEndpoint('EO/collections', { queryParams });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoCollection: async (args) => {
      if (args.length !== 1) return 'Usage: EoCollection <collection_id>';
      try {
        const result = await fetchEndpoint(`EO/collections/${args[0]}`);
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoCollectionQueryables: async (args) => {
      if (args.length !== 1) return 'Usage: EoCollectionQueryables <collection_id>';
      try {
        const result = await fetchEndpoint(`EO/collections/${args[0]}/queryables`);
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoServiceTypes: async (args) => {
      if (args.length !== 0) return 'Usage: EoServiceTypes';
      try {
        const result = await fetchEndpoint('EO/service_types');
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoServices: async (args) => {
      if (args.length !== 0) return 'Usage: EoServices';
      try {
        const result = await fetchEndpoint('EO/services');
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoServicesCreate: async (args) => {
      if (args.length !== 1) return 'Usage: EoServicesCreate <service_data_json>';
      try {
        const serviceData = JSON.parse(args[0]);
        const result = await fetchEndpoint('EO/services', { method: 'POST', data: serviceData });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error parsing JSON or making request: ' + e.message;
      }
    },
    EoServiceUpdate: async (args) => {
      if (args.length !== 2) return 'Usage: EoServiceUpdate <service_id> <service_data_json>';
      try {
        const serviceData = JSON.parse(args[1]);
        const result = await fetchEndpoint(`EO/services/${args[0]}`, { method: 'PATCH', data: serviceData });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error parsing JSON or making request: ' + e.message;
      }
    },
    EoService: async (args) => {
      if (args.length !== 1) return 'Usage: EoService <service_id>';
      try {
        const result = await fetchEndpoint(`EO/services/${args[0]}`);
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoServiceDelete: async (args) => {
      if (args.length !== 1) return 'Usage: EoServiceDelete <service_id>';
      try {
        const result = await fetchEndpoint(`EO/services/${args[0]}`, { method: 'DELETE' });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoServiceLogs: async (args) => {
      if (args.length !== 1) return 'Usage: EoServiceLogs <service_id>';
      try {
        const result = await fetchEndpoint(`EO/services/${args[0]}/logs`);
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoFiles: async (args) => {
      if (args.length > 1) return 'Usage: EoFiles [limit]';
      const limit = args[0] || '';
      const queryParams = limit ? `?limit=${encodeURIComponent(limit)}` : '';
      try {
        const result = await fetchEndpoint('EO/files', { queryParams });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoFileUpload: (args) => 'File upload not supported in terminal.',
    EoFile: async (args) => {
      if (args.length !== 1) return 'Usage: EoFile <path>';
      try {
        const result = await fetchEndpoint(`EO/files/${args[0]}`);
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    EoFileDelete: async (args) => {
      if (args.length !== 1) return 'Usage: EoFileDelete <path>';
      try {
        const result = await fetchEndpoint(`EO/files/${args[0]}`, { method: 'DELETE' });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    // KG Commands
    KgStartService: async (args) => {
      if (args.length !== 0) return 'Usage: KgStartService';
      try {
        const result = await fetchEndpoint('KG/api/start-service');
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    KgServiceStatus: async (args) => {
      if (args.length !== 0) return 'Usage: KgServiceStatus';
      try {
        const result = await fetchEndpoint('KG/api/service-status');
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    KgExecuteQuery: async (args) => {
      if (args.length !== 1) return 'Usage: KgExecuteQuery <query>';
      try {
        const result = await fetchEndpoint('KG/api/execute-query', {
          queryParams: `?query=${encodeURIComponent(args[0])}`,
        });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    KgExecuteAdvancedQuery: async (args) => {
      if (args.length !== 2) return 'Usage: KgExecuteAdvancedQuery <query> <sources>';
      try {
        const result = await fetchEndpoint('KG/api/execute-advanced-query', {
          queryParams: `?query=${encodeURIComponent(args[0])}&sources=${encodeURIComponent(args[1])}`,
        });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    KgSearchBasicMetaDataSet: async (args) => {
      if (args.length !== 1) return 'Usage: KgSearchBasicMetaDataSet <values>';
      try {
        const result = await fetchEndpoint('KG/api/search-basic-MetaDataSet', {
          queryParams: `?values=${encodeURIComponent(args[0])}`,
        });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    KgSearchDataSet: async (args) => {
      if (args.length !== 1) return 'Usage: KgSearchDataSet <values>';
      try {
        const result = await fetchEndpoint('KG/api/search-DataSet', {
          queryParams: `?values=${encodeURIComponent(args[0])}`,
        });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    KgSearchDataSetV1: async (args) => {
      if (args.length !== 2) return 'Usage: KgSearchDataSetV1 <values> <deprecatedFormat>';
      try {
        const result = await fetchEndpoint(`KG/api/search-DataSet/v1/Get/${args[0]}/${args[1]}`);
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    KgSearchDataSetV2: async (args) => {
      if (args.length !== 1) return 'Usage: KgSearchDataSetV2 <values>';
      try {
        const result = await fetchEndpoint(`KG/api/search-DataSet/v2/Get/${args[0]}`);
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    KgSearchRelatedDataSets: async (args) => {
      if (args.length !== 1) return 'Usage: KgSearchRelatedDataSets <id>';
      try {
        const result = await fetchEndpoint('KG/api/search-related-DataSets', {
          queryParams: `?id=${encodeURIComponent(args[0])}`,
        });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    KgSearchFullMetadata: async (args) => {
      if (args.length !== 1) return 'Usage: KgSearchFullMetadata <id>';
      try {
        const result = await fetchEndpoint('KG/api/search-full-metadata', {
          queryParams: `?id=${encodeURIComponent(args[0])}`,
        });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    KgSearchDatasetBreakdown: async (args) => {
      if (args.length !== 1) return 'Usage: KgSearchDatasetBreakdown <id>';
      try {
        const result = await fetchEndpoint(`KG/api/search-dataset-breakdown/${args[0]}`);
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    KgSearchDatasetBreakdownPost: async (args) => {
      if (args.length !== 2) return 'Usage: KgSearchDatasetBreakdownPost <id> <data_json>';
      try {
        const data = JSON.parse(args[1]);
        const result = await fetchEndpoint(`KG/api/search-dataset-breakdown/${args[0]}`, { method: 'POST', data });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error parsing JSON or making request: ' + e.message;
      }
    },
    KgSearchDatasetBreakdownWithOption: async (args) => {
      if (args.length !== 2) return 'Usage: KgSearchDatasetBreakdownWithOption <id> <options>';
      try {
        const result = await fetchEndpoint(`KG/api/search-dataset-breakdown-with-option/${args[0]}`, {
          queryParams: `?options=${encodeURIComponent(args[1])}`,
        });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    KgGenerateApiCall: async (args) => {
      if (args.length !== 3) return 'Usage: KgGenerateApiCall <id> <optionsJson> <downloadOption>';
      try {
        const result = await fetchEndpoint('KG/api/generate-api-call', {
          queryParams: `?id=${encodeURIComponent(args[0])}&optionsJson=${encodeURIComponent(
            args[1],
          )}&downloadOption=${encodeURIComponent(args[2])}`,
        });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    KgGenerateApiCallPost: async (args) => {
      if (args.length !== 2) return 'Usage: KgGenerateApiCallPost <id> <data_json>';
      try {
        const data = JSON.parse(args[1]);
        const result = await fetchEndpoint('KG/api/generate-api-call', {
          method: 'POST',
          queryParams: `?id=${encodeURIComponent(args[0])}`,
          data,
        });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error parsing JSON or making request: ' + e.message;
      }
    },
    // S3 Commands
    S3BucketCreate: async (args) => {
      if (args.length !== 1) return 'Usage: S3BucketCreate <bucket_name>';
      try {
        const result = await fetchEndpoint('S3/bucket/create', {
          method: 'POST',
          queryParams: `?bucketName=${encodeURIComponent(args[0])}`,
        });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    S3Buckets: async (args) => {
      if (args.length !== 0) return 'Usage: S3Buckets';
      try {
        const result = await fetchEndpoint('S3/buckets');
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    S3Bucket: async (args) => {
      if (args.length !== 1) return 'Usage: S3Bucket <bucket_name>';
      try {
        const result = await fetchEndpoint(`S3/bucket/${args[0]}`);
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    S3BucketDelete: async (args) => {
      if (args.length !== 1) return 'Usage: S3BucketDelete <bucket_name>';
      try {
        const result = await fetchEndpoint('S3/bucket/delete', {
          method: 'DELETE',
          queryParams: `?bucketName=${encodeURIComponent(args[0])}`,
        });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    S3BucketFilesUpload: (args) => 'File upload not supported in terminal.',
    S3BucketFiles: async (args) => {
      if (args.length < 1 || args.length > 2) return 'Usage: S3BucketFiles <bucket_name> [prefix]';
      const [bucketName, prefix = ''] = args;
      const queryParams = prefix ? `?prefix=${encodeURIComponent(prefix)}` : '';
      try {
        const result = await fetchEndpoint(`S3/bucket/${bucketName}/files`, { queryParams });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    S3BucketFilesPreview: async (args) => {
      if (args.length !== 2) return 'Usage: S3BucketFilesPreview <bucket_name> <file_path_data_json>';
      try {
        const filePathData = JSON.parse(args[1]);
        const result = await fetchEndpoint(`S3/bucket/${args[0]}/files/preview`, {
          method: 'POST',
          data: filePathData,
        });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error parsing JSON or making request: ' + e.message;
      }
    },
    S3BucketFilesDelete: async (args) => {
      if (args.length !== 2) return 'Usage: S3BucketFilesDelete <bucket_name> <key>';
      try {
        const result = await fetchEndpoint(`S3/bucket/${args[0]}/files/delete`, {
          method: 'DELETE',
          queryParams: `?key=${encodeURIComponent(args[1])}`,
        });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    S3FilesUpload: (args) => 'File upload not supported in terminal.',
    S3FilesUploadConf: (args) => 'File upload not supported in terminal.',
    S3FilesJsDef: async (args) => {
      if (args.length !== 1) return 'Usage: S3FilesJsDef <file_config_json>';
      try {
        const fileConfig = JSON.parse(args[0]);
        const result = await fetchEndpoint('S3/files/js/def', { method: 'POST', data: fileConfig });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error parsing JSON or making request: ' + e.message;
      }
    },
    // WorkflowProducts Commands
    WorkflowProducts: async (args) => {
      if (args.length !== 0) return 'Usage: WorkflowProducts';
      try {
        const result = await fetchEndpoint('WorkflowProducts');
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    WorkflowProductsCreate: async (args) => {
      if (args.length !== 1) return 'Usage: WorkflowProductsCreate <product_data_json>';
      try {
        const productData = JSON.parse(args[0]);
        const result = await fetchEndpoint('WorkflowProducts', { method: 'POST', data: productData });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error parsing JSON or making request: ' + e.message;
      }
    },
    WorkflowProduct: async (args) => {
      if (args.length !== 1) return 'Usage: WorkflowProduct <id>';
      try {
        const result = await fetchEndpoint(`WorkflowProducts/${args[0]}`);
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    WorkflowProductUpdate: async (args) => {
      if (args.length !== 2) return 'Usage: WorkflowProductUpdate <id> <product_data_json>';
      try {
        const productData = JSON.parse(args[1]);
        const result = await fetchEndpoint(`WorkflowProducts/${args[0]}`, { method: 'PUT', data: productData });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error parsing JSON or making request: ' + e.message;
      }
    },
    WorkflowProductDelete: async (args) => {
      if (args.length !== 1) return 'Usage: WorkflowProductDelete <id>';
      try {
        const result = await fetchEndpoint(`WorkflowProducts/${args[0]}`, { method: 'DELETE' });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    // Users Commands
    UsersCount: async (args) => {
      if (args.length !== 0) return 'Usage: UsersCount';
      try {
        const result = await fetchEndpoint('Users/count');
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    Users: async (args) => {
      if (args.length !== 0) return 'Usage: Users';
      try {
        const result = await fetchEndpoint('Users');
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    UsersCreate: async (args) => {
      if (args.length !== 1) return 'Usage: UsersCreate <user_data_json>';
      try {
        const userData = JSON.parse(args[0]);
        const result = await fetchEndpoint('Users', { method: 'POST', data: userData });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error parsing JSON or making request: ' + e.message;
      }
    },
    User: async (args) => {
      if (args.length !== 1) return 'Usage: User <id>';
      try {
        const result = await fetchEndpoint(`Users/${args[0]}`);
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    UserUpdate: async (args) => {
      if (args.length !== 2) return 'Usage: UserUpdate <id> <user_data_json>';
      try {
        const userData = JSON.parse(args[1]);
        const result = await fetchEndpoint(`Users/${args[0]}`, { method: 'PUT', data: userData });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error parsing JSON or making request: ' + e.message;
      }
    },
    UserDelete: async (args) => {
      if (args.length !== 1) return 'Usage: UserDelete <id>';
      try {
        const result = await fetchEndpoint(`Users/${args[0]}`, { method: 'DELETE' });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    UserHistory: async (args) => {
      if (args.length < 1 || args.length > 3) return 'Usage: UserHistory <id> [page] [pageSize]';
      const [id, page = '1', pageSize = '20'] = args;
      try {
        const result = await fetchEndpoint(`Users/${id}/history`, {
          queryParams: `?page=${encodeURIComponent(page)}&pageSize=${encodeURIComponent(pageSize)}`,
        });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    UserCredentials: async (args) => {
      if (args.length !== 1) return 'Usage: UserCredentials <id>';
      try {
        const result = await fetchEndpoint(`Users/${args[0]}/credentials`);
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error: ' + e.message;
      }
    },
    UserResetPassword: async (args) => {
      if (args.length !== 2) return 'Usage: UserResetPassword <id> <password_data_json>';
      try {
        const passwordData = JSON.parse(args[1]);
        const result = await fetchEndpoint(`Users/${args[0]}/reset-password`, { method: 'PUT', data: passwordData });
        return JSON.stringify(result, null, 2);
      } catch (e) {
        return 'Error parsing JSON or making request: ' + e.message;
      }
    },
  };
  const welcomeMessage = (
    <div style={{ marginBottom: 15 }}>
      <img src={logo} style={{ width: 200 }} />
      {/* <pre> 

       /$$$$$$$$  /$$$$$$  /$$   /$$ /$$$$$$$$ /$$   /$$
      | $$_____/ /$$__  $$| $$  | $$| $$_____/| $$  | $$
      | $$      | $$  \ $$| $$  | $$| $$      | $$  | $$
      | $$$$$   | $$  | $$| $$$$$$$$| $$$$$   | $$  | $$
      | $$__/   | $$  | $$|_____  $$| $$__/   | $$  | $$
      | $$      | $$  | $$      | $$| $$      | $$  | $$
      | $$$$$$$$|  $$$$$$/      | $$| $$$$$$$$|  $$$$$$/
      |________/ \______/       |__/|________/ \______/ 

      </pre> */}
      <span>Type "help" for all available commands.</span>
    </div>
  );

  useEffect(() => {
    keycloak.loadUserInfo().then((response) => {
      console.log(response);
      setPrompt('$' + response.preferred_username);
    });
    //setPrompt("$"+username)
  }, []);

  return (
    <div style={{ textAlign: 'center', height: '100vh' }}>
      <TerminalContextProvider>
        <ReactTerminal
          prompt={prompt}
          theme={theme}
          showControlBar={controlBar}
          showControlButtons={controlButtons}
          welcomeMessage={welcomeMessage}
          commands={commands}
          defaultHandler={(command, commandArguments) => {
            return `${command} is not recognized as an internal or external command. Type 'help' to see all available commands`;
          }}
          className='terminal-output'
        />
      </TerminalContextProvider>
    </div>
  );
}
